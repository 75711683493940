<template>
    <div>
        <div class="mb-4">For
            <ChargeTypeStatus :billingReport="billingReport" /> billing,
            here are the list of transactions within the billing period
            of <b>{{ startDate }}</b> and <b>{{ endDate }}</b>.
        </div>

        <!-- Select Actions and Items Per Page Options -->
        <b-row class="mt-4 mb-2">
            <b-col sm="6" offset-sm="6" md="4" offset-md="8" class="text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table ref="classificationTransactionsTable" show-empty striped hover :items="items" :fields="fields"
            :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

            <template v-slot:cell(dateReceived)="row">
                <span class="text-nowrap">{{ getFormattedDate(row.item.dateReceived) }}</span>
            </template>

            <template v-slot:cell(quantity)="row">
                <span class="numFont">{{ row.item.quantity.toLocaleString() }}</span>
            </template>

            <template v-slot:cell(dispatchNo)="row">
                <span class="numFont">
                    {{ row.item.dispatchNo }}
                </span>
            </template>

            <template v-slot:cell(notes)="row">
                <span class="truncate-text">
                    <truncate type="html" action-class="text-primary" :text="breakNotes(row.item.notes, 25)"
                        clamp="Show More" less="Show Less" :length="50" />
                </span>
            </template>

            <template v-slot:cell(classified)="row">
                <span v-if="isCompleted(row.item)">
                    <b-badge variant="success">YES</b-badge>
                </span>
                <span v-else>
                    <b-badge variant="secondary">NO</b-badge>
                </span>
            </template>

            <template v-slot:cell(vatExclusiveRates)="row">
                <span class="numFont">
                    {{ formatMoneyValue('PHP', row.item.vatExclusiveRates) }}
                </span>
            </template>

            <template v-slot:cell(vatExclusiveAmount)="row">
                <span class="numFont">
                    {{ formatMoneyValue('PHP', row.item.vatExclusiveAmount) }}
                </span>
            </template>

            <template v-slot:cell(actions)="row">
                <b-button v-if="isClassified(row.item)" size="sm" v-b-tooltip.hover.top="'View Classification'"
                    variant="dark" @click.stop="updateSelectedRow(row.item, 'view')" class="mr-1 mt-1">
                    <em class="fa fa-eye"></em>
                </b-button>
                <b-button v-else size="sm" v-b-tooltip.hover.top="'Start Classification'" variant="primary"
                    @click.stop="updateSelectedRow(row.item, 'start')" class="mr-1 mt-1">
                    <img class="folder-tree" src="img/folder-tree.svg" alt="Classification" />
                </b-button>

                <span v-if="isCompleted(row.item) === false">
                    <b-button size="sm" v-b-tooltip.hover.top="'Edit Classification'" variant="warning"
                        @click.stop="updateSelectedRow(row.item, 'edit')" class="mr-1 mt-1">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button size="sm" v-b-tooltip.hover.top="'Complete Classification'" variant="primary"
                        @click.stop="updateSelectedRow(row.item, 'complete')" class="mr-1 mt-1">
                        <em class="fa fa-check"></em>
                    </b-button>
                </span>
            </template>
        </b-table>

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>

        <!-- Modals -->
        <ClassifyDispatchAssets :allAssetTypesObj="allAssetTypesObj" />
        <ImageViewDialog />
        <MarkClassificationAsDone />
    </div>
</template>

<script>
// Components
import ChargeTypeStatus from '../ChargeTypeStatus.vue';
import ClassifyDispatchAssets from '@/views/transactions/classification/ClassifyDispatchAssets';
import ImageViewDialog from '@/views/transactions/common/ImageViewDialog';
import MarkClassificationAsDone from '@/views/transactions/classification/MarkClassificationAsDone';

// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';
import { DateUtil } from '@/utils/dateutil';

// DAO
import dispatchDAO from '@/database/dispatches';
import classificationDAO from '@/database/classifications';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
    name: 'billing-transactions-classification-tab',
    components: {
        ChargeTypeStatus,
        ClassifyDispatchAssets,
        ImageViewDialog,
        MarkClassificationAsDone,
        Loading,
        truncate
    },
    props: {
        allAssetTypesObj: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            items: [],
            fields: [],
            defaultFields: [
                {
                    key: 'dateReceived',
                    label: 'Date',
                    sortable: false,
                },
                {
                    key: 'dispatchNo',
                    label: 'Dispatch No.',
                    sortable: false,
                },
                {
                    key: 'notes',
                    sortable: false,
                },
                {
                    key: 'classified',
                    label: 'Classified?',
                    sortable: false,
                    class: 'text-center'
                },
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            billingReport: {},
            dispatches: {},
            classifications: {},

            // Check for loader
            isLoading: false,
        }
    },
    computed: {
        startDate() {
            return DateUtil.getFormattedDate(this.billingReport.startDate);
        },
        endDate() {
            return DateUtil.getFormattedDate(this.billingReport.endDate);
        },
        billingReportNo() {
            return this.billingReport && this.billingReport.billingReportNo ? this.billingReport.billingReportNo : '';
        },
        assetOwnerId() {
            return this.billingReport && this.billingReport.assetOwnerId ? this.billingReport.assetOwnerId : '';
        },
        clientId() {
            return this.billingReport && this.billingReport.clientId ? this.billingReport.clientId : '';
        },
        clientAccountId() {
            return this.billingReport && this.billingReport.clientAccountId ? this.billingReport.clientAccountId : '';
        },
        chargeType() {
            let billingReport = this.billingReport ? this.billingReport : {};
            return billingReport.chargeType ? billingReport.chargeType : '-';
        },
        chargeTypeRate() {
            let billingReport = this.billingReport ? this.billingReport : {};
            let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};
            return chargeTypeDetails.vatExclusiveRate ? parseFloat(chargeTypeDetails.vatExclusiveRate) : 0;
        },
        assetType() {
            let billingReport = this.billingReport ? this.billingReport : {};
            let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};
            return chargeTypeDetails.assetType ? chargeTypeDetails.assetType : '';
        },
        assetTypeId() {
            let billingReport = this.billingReport ? this.billingReport : {};
            let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};
            return chargeTypeDetails.assetTypeId ? chargeTypeDetails.assetTypeId : '';
        },
        dispatchIds() {
            if (this.items && !_.isEmpty(this.items)) {
                return _.map(this.items, 'dispatchId');
            }
            return [];
        },
        chargeTypeKeyCol() {
            return 'total' + this.chargeType;
        },
        chargeTypeLabelCol() {
            return 'Total ' + this.chargeType;
        }
    },
    watch: {
        items: {
            handler(newVal) {
                if (newVal && !_.isEmpty(newVal)) {
                    this.updateComputationSummary();
                }
            },
            deep: true
        }
    },
    async mounted() {
        // init billingReport
        this.billingReport = { ...this.$store.getters.currBillingReport };
        await this.onReset(this.billingReport);

        EventBus.$on("onUpdateTransactionsClassification", async billingReport => {
            await this.onReset(billingReport);
        });

        EventBus.$on('onCloseSaveClassification', async () => {
            await this.retrieveClassifications();
            this.processDispatches(this.dispatches);
        });
    },
    methods: {
        async onReset(billingReport) {
            this.billingReport = billingReport;
            if (this.billingReport && !_.isEmpty(this.billingReport)) {
                // preload items from transactions
                this.items = this.billingReport.transactions;
                // retrieve other transactions not included to the original list
                this.updateTableFields();
                await this.retrieveTransactions();
            }
        },
        getChargeTypeKeyVal(item) {
            return parseInt(item[this.chargeTypeKeyCol]);
        },
        updateTableFields() {
            this.fields = [...this.defaultFields];

            this.fields.push({
                key: this.chargeTypeKeyCol,
                label: this.chargeTypeLabelCol,
                sortable: false,
                thClass: 'text-center',
                tdClass: 'text-right numFont'
            });
            this.fields.push({
                key: 'vatExclusiveRates',
                label: 'VAT EX Rate',
                sortable: false,
                thClass: 'text-center',
                tdClass: 'text-right'
            });
            this.fields.push({
                key: 'vatExclusiveAmount',
                label: 'VAT EX Amount',
                sortable: false,
                thClass: 'text-center',
                tdClass: 'text-right'
            });
            this.fields.push({
                key: 'actions',
                thClass: 'text-center'
            });

            // refresh table
            if (this.$refs.classificationTransactionsTable) {
                this.$refs.classificationTransactionsTable.refresh();
            }
        },

        async retrieveTransactions() {
            try {
                // show loading indicator
                this.isLoading = true;

                // Dispatches
                let dispatchFilter = {
                    status: 'Received',
                    receivedStartDate: this.billingReport.startDate,
                    receivedEndDate: this.billingReport.endDate,
                    accountNo: { id: this.clientAccountId },
                    sourceCompany: { id: this.clientId },
                    destinationCompany: { id: this.assetOwnerId }
                };
                this.dispatches = await dispatchDAO.getDispatches(dispatchFilter);

                // Include only dispatch with assetTypeId
                this.dispatches = _.filter(this.dispatches, dispatch => {
                    let isIncluded = false;
                    let dispatchAsset = this.getDispatchAsset(dispatch, this.assetTypeId);
                    if (dispatchAsset && !_.isEmpty(dispatchAsset)) {
                        isIncluded = true;
                    }
                    return isIncluded;
                });

                // Classifications
                await this.retrieveClassifications();

                this.processDispatches(this.dispatches);
            } catch (error) {
                this.$toaster.error('Error loading transactions. Please reload the page again.');
            } finally {
                // hide loading indicator
                this.isLoading = false;
            }
        },
        getDispatchAsset(dispatch, assetTypeId) {
            return _.find(dispatch.assets, o => {
                return o.assetTypeId === assetTypeId;
            });
        },

        async retrieveClassifications() {
            let dispatchIds = this.dispatches && !_.isEmpty(this.dispatches) ? _.map(this.dispatches, 'dispatchId') : [];
            let classificationFilter = {
                dispatchIds: dispatchIds,
                assetTypeId: this.assetTypeId
            };
            let results = await classificationDAO.getClassifications(classificationFilter);
            this.classifications = _.keyBy(results, 'dispatchId');
        },
        getChargeTypeQuantity(dispatchId) {
            let classification = this.classifications[dispatchId];
            let classifiedCondition = _.find(classification.conditions, o => {
                return o.condition === this.chargeType;
            });
            return classifiedCondition ? classifiedCondition.quantity : 0;
        },
        processDispatches(dispatches) {
            // reset
            this.items = [];
            _.forEach(dispatches, dispatch => {
                let item = { ...dispatch };

                let sourceCompanyId = dispatch.source.companyId;
                item.type = sourceCompanyId === this.assetOwnerId ? 'Hire' : 'Dehire';
                let dispatchAsset = this.getDispatchAsset(dispatch, this.assetTypeId);
                item.quantity = dispatchAsset.actualQuantity;

                let dispatchId = dispatch.dispatchId;
                if (this.isCompleted(dispatch)) {
                    item.classified = true;
                    item[this.chargeTypeKeyCol] = this.getChargeTypeQuantity(dispatchId);
                } else {
                    item.classified = false;
                    item[this.chargeTypeKeyCol] = 0;
                }

                item.vatExclusiveRates = this.chargeTypeRate;
                item.vatExclusiveAmount = parseInt(item[this.chargeTypeKeyCol]) * parseFloat(this.chargeTypeRate);

                this.items.push(item);
            });
            this.totalRows = this.items.length;

            // sort by ascending order
            this.items = _.sortBy(this.items, ['dateReceived']);
            // refresh table
            if (this.$refs.classificationTransactionsTable) {
                this.$refs.classificationTransactionsTable.refresh();
            }

            this.updateComputationSummary();
        },
        updateComputationSummary() {
            // update transactions
            this.billingReport.transactions = this.items;
            // update total charge type amount
            this.billingReport.totalChargeTypeAmount = this.getTotalClassificationAmount();

            this.$emit('onUpdateBillingReport', this.billingReport);
            EventBus.$emit('onUpdateChargeTypeAmount', this.billingReport.totalChargeTypeAmount);
        },
        getTotalClassificationAmount() {
            let totalAmount = 0;
            _.forEach(this.items, item => {
                totalAmount += parseFloat(item.vatExclusiveAmount);
            });
            return totalAmount;
        },

        getAssetTypeParam(dispatch, assetTypeId) {
            let dispatchAsset = this.getDispatchAsset(dispatch, assetTypeId);
            return {
                id: this.assetTypeId,
                name: this.assetType,
                expected: dispatchAsset && dispatchAsset.expectedQuantity ? dispatchAsset.expectedQuantity : 0,
                actual: dispatchAsset && dispatchAsset.actualQuantity ? dispatchAsset.actualQuantity : 0,
            };
        },
        updateSelectedRow(dispatch, action) {
            const classification = this.classifications[dispatch.dispatchId];

            switch (action) {
                case 'start':
                case 'edit':
                    {
                        const params = {
                            dispatch: dispatch,
                            assetType: this.getAssetTypeParam(dispatch, this.assetTypeId),
                            classification: classification
                        }
                        EventBus.$emit('onClassifyDispatchAssets', params);
                        this.$bvModal.show('classify-dispatch-assets');
                    }
                    break;
                case 'view':
                case 'complete':
                    {
                        const params = {
                            classification: classification,
                            completed: action === 'complete',
                        }
                        EventBus.$emit('onViewClassificationDetails', params);
                        this.$bvModal.show('mark-classification-as-done');
                    }
                    break;
            }
        },
        isClassified(dispatch) {
            let classification = this.classifications[dispatch.dispatchId];
            return classification && !_.isEmpty(classification);
        },
        isCompleted(dispatch) {
            let classification = this.classifications[dispatch.dispatchId];
            return this.isClassified(dispatch) && classification.isCompleted;
        },

        // UTILS
        getFormattedDate(date) {
            return DateUtil.getFormattedDate(date);
        },
        breakNotes(notes, length) {
            return notes.length > length ? notes.replace(new RegExp(`(.{1,${length}})\\s`, 'g'), '$1<br>') : notes;
        },
        formatMoneyValue(currency, number) {
            return BillingReportUtil.formatMoneyValue(currency, number);
        }
    },
    beforeDestroy() {
        EventBus.$off('onUpdateTransactionsClassification');
        EventBus.$off('onCloseSaveClassification');
    },
}
</script>

<style scoped>
.field-label-main {
    font-weight: bold;
    margin-top: 3px;
    font-size: 18px;
}

.field-value-main {
    font-weight: bold;
    font-size: 18px !important;
    margin-right: 10px;
}
</style>