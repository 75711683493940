<template>
    <div>
        <!-- Beginning and Ending Assets-On-Hire -->
        <b-row class="mt-4">
            <b-col lg="6" md="6" sm="6" class="mt-1">
                <b-card title="Assets-on-Hire" tag="article" class="my-2">
                    <div>
                        <span class="field-label">Beginning for the Period</span>
                        <span class="field-value numFont" v-if="startingQuantity >= 0">
                            {{ startingQuantity.toLocaleString() }}
                        </span>
                        <span class="field-value numFont" v-else>
                            ({{ (-1 * startingQuantity).toLocaleString() }})
                        </span>
                    </div>
                    <div>
                        <span class="field-label">Ending for the Period</span>
                        <span class="field-value numFont" v-if="endingQuantity >= 0">
                            {{ endingQuantity.toLocaleString() }}
                        </span>
                        <span class="field-value numFont" v-else>
                            ({{ (-1 * endingQuantity).toLocaleString() }})
                        </span>
                    </div>
                </b-card>
            </b-col>

            <!-- Beginning and Ending Asset Days -->
            <b-col lg="6" md="6" sm="6" class="mt-1" v-if="rentFrequency === 'Per Day'">
                <b-card title="Asset Days" tag="article" class="my-2">
                    <div>
                        <span class="field-label">Beginning for the Period</span>
                        <span class="field-value numFont" v-if="startingDays >= 0">
                            {{ startingDays.toLocaleString() }}
                        </span>
                        <span class="field-value numFont" v-else>
                            ({{ (-1 * startingDays).toLocaleString() }})
                        </span>
                    </div>
                    <div>
                        <span class="field-label">Ending for the Period</span>
                        <span class="field-value numFont" v-if="endingDays >= 0">
                            {{ endingDays.toLocaleString() }}
                        </span>
                        <span class="field-value numFont" v-else>
                            ({{ (-1 * endingDays).toLocaleString() }})
                        </span>
                    </div>
                </b-card>
            </b-col>

            <!-- Beginning and Ending Asset Hours -->
            <b-col lg="6" md="6" sm="6" class="mt-1" v-else>
                <b-card title="Asset Hours" tag="article" class="my-2">
                    <div>
                        <span class="field-label">Beginning for the Period</span>
                        <span class="field-value numFont" v-if="startingHours >= 0">
                            {{ startingHours.toLocaleString() }}
                        </span>
                        <span class="field-value numFont" v-else>
                            ({{ (-1 * startingHours).toLocaleString() }})
                        </span>
                    </div>
                    <div>
                        <span class="field-label">Ending for the Period</span>
                        <span class="field-value numFont" v-if="endingHours >= 0">
                            {{ endingHours.toLocaleString() }}
                        </span>
                        <span class="field-value numFont" v-else>
                            ({{ (-1 * endingHours).toLocaleString() }})
                        </span>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Others
import EventBus from '@/shared/event-bus';
import _ from 'lodash';

export default {
    name: 'rental-asset-summary',
    data() {
        return {
            billingReport: {},
        }
    },
    computed: {
        chargeTypeDetails() {
            return this.billingReport && this.billingReport.chargeTypeDetails ? this.billingReport.chargeTypeDetails : {};
        },
        rentFrequency() {
            return this.chargeTypeDetails.rentFrequency ? this.chargeTypeDetails.rentFrequency : '';
        },
        startingQuantity() {
            return this.chargeTypeDetails.startingQuantity ? this.chargeTypeDetails.startingQuantity : 0;
        },
        endingQuantity() {
            return this.chargeTypeDetails.endingQuantity ? this.chargeTypeDetails.endingQuantity : 0;
        },
        startingDays() {
            return this.chargeTypeDetails.startingDays ? this.chargeTypeDetails.startingDays : 0;
        },
        endingDays() {
            return this.chargeTypeDetails.endingDays ? this.chargeTypeDetails.endingDays : 0;
        },
        startingHours() {
            return this.chargeTypeDetails.startingHours ? this.chargeTypeDetails.startingHours : 0;
        },
        endingHours() {
            return this.chargeTypeDetails.endingHours ? this.chargeTypeDetails.endingHours : 0;
        },
        rentRate() {
            return this.chargeTypeDetails.rentRate ? this.chargeTypeDetails.rentRate : 0;
        }
    },
    mounted() {
        // reset
        this.adjustedQuantity = 0;

        EventBus.$on("onUpdateComputationFromTransactions", billingReport => {
            this.billingReport = billingReport;
            this.updateComputationSummaryFromTransactions();
        });

        EventBus.$on("onUpdateComputationFromDelayedTransfers", delayedTransfers => {
            if (!_.isEmpty(this.billingReport)) {
                this.billingReport.delayedTransfers = delayedTransfers;
                this.updateComputationSummaryFromDelayedTransfer();
            }
        });
    },
    methods: {
        getAdjustedQuantityFromTransactions() {
            let adjustedQuantity = 0;

            _.forEach(this.billingReport.transactions, item => {
                if (item.type === 'Hire') {
                    adjustedQuantity += item.quantity;
                } else if (item.type === 'Dehire') {
                    adjustedQuantity -= item.quantity;
                }
            });

            return adjustedQuantity;
        },
        updateComputationSummaryFromTransactions() {
            // Ending Quantity
            let adjustedQuantity = this.getAdjustedQuantityFromTransactions();
            let startingQuantity = this.billingReport.chargeTypeDetails.startingQuantity;
            this.billingReport.chargeTypeDetails.endingQuantity = startingQuantity + adjustedQuantity;

            if (this.rentFrequency === 'Per Day') {
                // Ending Days
                let startingDays = this.billingReport.chargeTypeDetails.startingDays;
                let adjustmentDays = _.sum(_.map(this.billingReport.transactions, 'totalDays'));
                this.billingReport.chargeTypeDetails.endingDays = startingDays + adjustmentDays;

            } else if (this.rentFrequency === 'Per Hour') {
                // Ending Hours
                let startingHours = this.billingReport.chargeTypeDetails.startingHours;
                let adjustmentHours = _.sum(_.map(this.billingReport.transactions, 'totalHours'));
                this.billingReport.chargeTypeDetails.endingHours = startingHours + adjustmentHours;
            }

            // update total charge type amount
            this.billingReport.totalChargeTypeAmount = this.getTotalRentalAmount();
            EventBus.$emit('onUpdateChargeTypeAmount', this.billingReport.totalChargeTypeAmount);
            this.$emit('onUpdateBillingReport', this.billingReport);

            // load delayed transfer transactions
            EventBus.$emit('onLoadDelayedTransfers', this.billingReport);
        },

        getAdjustedQuantityFromDelayedTransfers() {
            let adjustedQuantity = 0;

            _.forEach(this.billingReport.delayedTransfers, item => {
                adjustedQuantity -= item.quantity;
            });

            return adjustedQuantity;
        },
        updateComputationSummaryFromDelayedTransfer() {
            // Ending Quantity
            let adjustedQuantity = this.getAdjustedQuantityFromDelayedTransfers();
            let endingQuantity = this.billingReport.chargeTypeDetails.endingQuantity;
            this.billingReport.chargeTypeDetails.endingQuantity = endingQuantity + adjustedQuantity;

            if (this.rentFrequency === 'Per Day') {
                // Ending Days
                let adjustmentDays = _.sum(_.map(this.billingReport.delayedTransfers, 'totalDays'));
                this.billingReport.chargeTypeDetails.endingDays += adjustmentDays;

            } else if (this.rentFrequency === 'Per Hour') {
                // Ending Hours
                let adjustmentHours = _.sum(_.map(this.billingReport.delayedTransfers, 'totalHours'));
                this.billingReport.chargeTypeDetails.endingHours += adjustmentHours;
            }

            // update total charge type amount
            this.billingReport.totalChargeTypeAmount = this.getTotalRentalAmount();
            EventBus.$emit('onUpdateChargeTypeAmount', this.billingReport.totalChargeTypeAmount);
            this.$emit('onUpdateBillingReport', this.billingReport);
        },

        getTotalRentalAmount() {
            let totalAmount = 0;
            if (this.rentFrequency === 'Per Day') {
                totalAmount = this.rentRate * this.billingReport.chargeTypeDetails.endingDays;
            } else if (this.rentFrequency === 'Per Hour') {
                totalAmount = this.rentRate * this.billingReport.chargeTypeDetails.endingHours;
            }
            return parseFloat(totalAmount);
        },
    },
    beforeDestroy() {
        EventBus.$off('onUpdateComputationFromTransactions');
        EventBus.$off('onUpdateComputationFromDelayedTransfers');
    },
}
</script>

<style scoped>
.field-label {
    font-weight: bold;
    margin-right: 5px;
    margin-top: 3px;
    font-size: 16px;
}

.field-value {
    font-size: 18px !important;
}

.field-label-main {
    font-weight: bold;
    margin-top: 3px;
    font-size: 18px;
}

.field-value-main {
    font-weight: bold;
    font-size: 18px !important;
    margin-right: 10px;
}
</style>